import { DuplicateFlow, DuplicateFlows, Account } from '../../types'
import { Element } from '../../../../shared/domain/flows/types.no-deps'
import { useState } from 'react'
import { compareFlows, compareWorkflowTags } from './logic'
import UpdateWizard from './updateWizard'
import { EyeIcon, CopiesIcon, StopIcon, DeleteIcon, ChevronDown, ChevronUp } from './icons'
import {
    Button,
    Box,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
} from '@mui/material'
type ViewCopiesProps = {
    duplicateFlows: DuplicateFlow[]
    instances: Account[]
    setPage: (page: ViewCopiesProps['currentPage']) => void
    setFlow: (df: DuplicateFlow[]) => void
    currentPage: 'home' | 'update' | 'compare'
    toWorkflows: DuplicateFlows
}
export function ViewCopies(props: ViewCopiesProps) {
    const [selectedFlow, setSelectedFlow] = useState<DuplicateFlow>()
    const [updateFlows, setUpdateFlows] = useState<DuplicateFlow[]>([])

    if (props.currentPage === 'update' && updateFlows.length > 0 && selectedFlow)
        return (
            <UpdateWizard
                selectedFlow={selectedFlow}
                selectedFlows={updateFlows}
                instances={props.instances}
                duplicateFlows={props.duplicateFlows}
                toWorkflows={Object.values(props.toWorkflows).flat()}
            />
        )

    function Row(props: { df: DuplicateFlow; instances: Account[]; setFlow: (df: DuplicateFlow[]) => void }) {
        const [open, setOpen] = useState(false)
        const instance = props.instances.find((inst) => inst.customerId === props.df.customerId)

        const differences =
            selectedFlow &&
                selectedFlow !== props.df &&
                selectedFlow.workflow?.flow?.steps &&
                props.df.workflow?.flow?.steps
                ? compareFlows(selectedFlow.workflow?.flow?.steps, props.df.workflow?.flow?.steps)
                : null
        const tagDifferences =
            selectedFlow && selectedFlow !== props.df
                ? compareWorkflowTags(selectedFlow?.workflow, props.df.workflow)
                : null

        const diff =
            differences &&
            tagDifferences &&
            (differences.missingSteps.length === 0 &&
                differences.newSteps.length === 0 &&
                tagDifferences?.added.length === 0 &&
                tagDifferences.missing.length === 0 ? (
                <div className="no-change">No Changes</div>
            ) : (
                <>
                    <div className="missing">
                        {' '}
                        - <div className="count">{differences.missingSteps.length + tagDifferences.missing.length}</div>
                    </div>
                    <div className="added">
                        {' '}
                        + <div className="count">{differences.newSteps.length + +tagDifferences.added.length}</div>
                    </div>
                </>
            ))
        const hasDifferences =
            differences &&
            tagDifferences &&
            (differences.newSteps.length > 0 ||
                differences.missingSteps.length > 0 ||
                tagDifferences.added.length > 0 ||
                tagDifferences.missing.length > 0)
        const renderHistory = (differences: any) => {
            if (!hasDifferences) return null

            return (
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table size="small">
                                    <TableBody>
                                        {tagDifferences.missing.map((tag: string, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <div className="missing">
                                                        <div className="count">-</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>Tag #{tag}</TableCell>
                                            </TableRow>
                                        ))}
                                        {differences.missingSteps.map((element: Element, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <div className="missing">
                                                        <div className="count">-</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {element.label.trim() !== '' ? element.label : '(No name)'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {tagDifferences.added.map((tag: string, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <div className="added">
                                                        <div className="count">+</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>Tag #{tag}</TableCell>
                                            </TableRow>
                                        ))}
                                        {differences.newSteps.map((element: Element, index: number) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <div className="added">
                                                        <div className="count">+</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {element.label.trim() !== '' ? element.label : '(No name)'}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )
        }

        const buttons = selectedFlow ? (
            props.df.customerId === selectedFlow.customerId && props.df.workflow.id === selectedFlow.workflow.id ? (
                <Button className="selected stop" onClick={() => setSelectedFlow(undefined)}>
                    <StopIcon /> Stop
                </Button>
            ) : hasDifferences ? (
                <a onClick={() => setOpen(!open)}>
                    {diff}
                    {open ? <ChevronDown /> : <ChevronUp />}
                </a>
            ) : differences ? (
                <>{diff}</>
            ) : (
                ''
            )
        ) : (
            <>
                <Button onClick={() => props.setFlow([props.df])}>
                    <CopiesIcon />
                    Copy
                </Button>
                <Button
                    onClick={() => {
                        setSelectedFlow(props.df)
                        setUpdateFlows([])
                    }}
                >
                    Compare
                </Button>
            </>
        )

        const viewButton = props.df.workflow.procedure ? (
            <a
                rel="noreferrer"
                href={`https://${instance?.serverURL}/procedures/${props.df.workflow.procedure.id}/tasks/workflows/${props.df.workflow.id}/edit`}
                target="_blank"
            >
                <Button className="icon-only">
                    <EyeIcon />
                </Button>
            </a>
        ) : null

        return (
            <>
                <TableRow>
                    {selectedFlow ? (
                        selectedFlow !== props.df ? (
                            <TableCell>
                                <Checkbox
                                    onChange={(event) => {
                                        if (event.target.checked && updateFlows.indexOf(props.df) === -1)
                                            updateFlows.push(props.df)
                                        else if (!event.target.checked)
                                            updateFlows.splice(updateFlows.indexOf(props.df), 1)
                                        setUpdateFlows([...updateFlows])
                                    }}
                                    checked={updateFlows.indexOf(props.df) > -1}
                                />
                            </TableCell>
                        ) : (
                            <TableCell />
                        )
                    ) : (
                        ''
                    )}
                    <TableCell>{props.df.workflow.name}</TableCell>
                    <TableCell>{props.df.workflow.procedure?.name}</TableCell>
                    <TableCell>{instance?.customerName}</TableCell>
                    <TableCell width={250} align="right">
                        <>
                            {buttons}
                            {viewButton}
                        </>
                    </TableCell>
                </TableRow>
                {renderHistory(differences)}
            </>
        )
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {selectedFlow ? (
                                <TableCell>
                                    <Checkbox
                                        onChange={(event) => {
                                            if (event.target.checked)
                                                setUpdateFlows(props.duplicateFlows.filter((df) => df !== selectedFlow))
                                            else if (!event.target.checked) setUpdateFlows([])
                                        }}
                                        checked={props.duplicateFlows.length - 1 === updateFlows.length}
                                    />
                                </TableCell>
                            ) : (
                                ''
                            )}
                            <TableCell>Name</TableCell>
                            <TableCell>Procedure</TableCell>
                            <TableCell>Instance</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.duplicateFlows.map((df, index) => (
                            <Row key={index} df={df} instances={props.instances} setFlow={props.setFlow} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedFlow && updateFlows.length > 0 && (
                <div className="bottom-action-bar">
                    <div className="info">
                        {`${updateFlows.length} Instances Selected`}
                        <div className="line"></div>
                        <a onClick={() => setUpdateFlows([])}>
                            <DeleteIcon /> Clear Selection
                        </a>
                    </div>
                    <Button variant="contained" onClick={() => props.setPage('update')}>
                        {`Update ${updateFlows.length} Instances`}
                    </Button>
                </div>
            )}
        </>
    )
}

export default ViewCopies
