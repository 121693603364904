import { Step, Element } from '../../../../shared/domain/flows/types.no-deps'
import { isConcreteStep } from './helper'
import type { UnitQuestion, Updates, UserPrefs, DuplicateFlow, SubtaskQuestion } from '../../types'
import { Account } from '../../types'
export const findUnitQuestions = (steps: Step[], unitQuestions: UnitQuestion[]) => {
    // Loop through a flow and find all questions that use units
    steps.forEach((step) => {
        if (!isConcreteStep(step)) {
            return
        }
        step.elements.forEach((element) => {
            if (element.type === 'loop') {
                unitQuestions.push({
                    questionId: element.id,
                    questionType: 'loop',
                    unitTypeId: element.unitType,
                    questionLabel: element.label,
                })
                element.unitFilters.forEach((filter) => {
                    if (
                        filter.type === 'ParentInList' ||
                        filter.type === 'ParentIsSubject' ||
                        filter.type === 'ParentIsUserSelectable'
                    )
                        unitQuestions.push({
                            questionId: element.id,
                            questionType: 'unit-filter-' + filter.type,
                            unitTypeId: filter.unitType,
                            questionLabel: element.label,
                        })
                })
                unitQuestions = unitQuestions.concat(findUnitQuestions(element.steps, unitQuestions))
            }

            if (element.type === 'unit-select') {
                element.unitTypes.forEach((unitTypeId) => {
                    unitQuestions.push({
                        questionId: element.id,
                        questionType: 'unit-select',
                        unitTypeId: unitTypeId,
                        questionLabel: element.label,
                    })
                })
            }

            if (element.type === 'section') {
                unitQuestions = unitQuestions.concat(findUnitQuestions(element.steps, unitQuestions))
            }
            if (element.type === 'loop') {
                unitQuestions = unitQuestions.concat(findUnitQuestions(element.steps, unitQuestions))
            }
        })
    })

    return unitQuestions.filter(
        (obj1, i, arr) =>
            arr.findIndex(
                (obj2) =>
                    obj2.questionId === obj1.questionId &&
                    obj1.questionType === obj2.questionType &&
                    obj1.unitTypeId === obj2.unitTypeId
            ) === i
    )
}
export const findSubtaskQuestions = (steps: Step[], subtaskQuestions: SubtaskQuestion[]) => {
    // Loop through a flow and find all questions that use units
    steps.forEach((step) => {
        if (!isConcreteStep(step)) {
            return
        }
        step.elements.forEach((element) => {
            if (element.type === 'flow') {
                subtaskQuestions.push({
                    questionId: element.id,
                    questionType: 'flow',
                    workflowId: element.workflow,
                    questionLabel: element.label,
                })
            }

            if (element.type === 'section') {
                subtaskQuestions = subtaskQuestions.concat(findSubtaskQuestions(element.steps, subtaskQuestions))
            }
            if (element.type === 'loop') {
                subtaskQuestions = subtaskQuestions.concat(findSubtaskQuestions(element.steps, subtaskQuestions))
            }
        })
    })

    return subtaskQuestions.filter(
        (obj1, i, arr) =>
            arr.findIndex((obj2) => obj2.questionId === obj1.questionId && obj1.questionType === obj2.questionType) ===
            i
    )
}
export const findElementsOfType = (steps: Step[], foundQuestions: Element[], types: Element['type'][]) => {
    // Loop through a flow and find all questions of given type
    steps.forEach((step) => {
        if (isConcreteStep(step))
            step.elements.forEach((element) => {
                if (types.indexOf(element.type) > -1) {
                    foundQuestions.push(element)
                }
                if (element.type === 'section' || element.type === 'loop') {
                    foundQuestions = foundQuestions.concat(findElementsOfType(element.steps, foundQuestions, types))
                }
            })
    })
    // Return questions and remove duplicates where ID and type are the same
    return foundQuestions.filter(
        (question1, i, arr) =>
            arr.findIndex((question2) => question2.id === question1.id && question1.type === question2.type) === i
    )
}
export const getAllSteps = (steps: Step[], foundSteps: Step[]) => {
    steps.forEach((step) => {
        if (step.type !== 'placeholder')
            step.elements.map((element) => {
                if (element.type === 'section') foundSteps.concat(getAllSteps(element.steps, foundSteps))
            })
        foundSteps.push(step)
    })
    return foundSteps
}
const postApi = async (account: Account, path: string, data: string) => {
    try {
        var options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + account.token,
            },
            body: data,
        }

        const response = await fetch('https://' + account.serverURL + '/' + path, options)

        return await response.json()
    } catch (e) {
        console.log('Error:', e)
        return
    }
}
function getSimilarUnitTypeMatch(unitQuestion: UnitQuestion, newWorkflow: Updates) {
    // Check whether a similar unit mapping has already been made
    const similarUnit = newWorkflow.unitQuestions.find((uq: UnitQuestion) => {
        return uq.newUnitTypeId !== undefined && uq.unitTypeId === unitQuestion.unitTypeId
    })

    if (similarUnit) return similarUnit.newUnitTypeId
    return
}

export function setUnitTypeId(newUnitTypeId: number, unitQuestion: UnitQuestion, newWorkflow: Updates) {
    // Update the state with the new unit mappings
    const updatedUnitQuestions = newWorkflow.unitQuestions
        .map((uq) => {
            if (
                uq.questionId === unitQuestion.questionId &&
                unitQuestion.questionType === uq.questionType &&
                unitQuestion.unitTypeId === uq.unitTypeId
            ) {
                uq.newUnitTypeId = newUnitTypeId
            }
            return uq
        })
        .map((uq) => {
            if (
                uq.newUnitTypeId === undefined &&
                getSimilarUnitTypeMatch(uq, newWorkflow) !== undefined &&
                uq.questionId !== unitQuestion.questionId
            ) {
                uq.newUnitTypeId = getSimilarUnitTypeMatch(uq, newWorkflow)
            }
            return uq
        })
    return [...updatedUnitQuestions]
}
export function setWorkflowId(
    newWorkflowId: number,
    workflowQuestion: SubtaskQuestion,
    newWorkflow: Updates,
    toInstanceWorkflows: DuplicateFlow[]
) {
    // Update the state with the new workflow mapping*s
    const updateWorkflowQuestions = newWorkflow.subtaskQuestions.map((uq) => {
        if (uq.questionId === workflowQuestion.questionId && workflowQuestion.questionType === uq.questionType) {
            uq.newWorkflowId = newWorkflowId
            uq.newProcedureId = toInstanceWorkflows.find((tw) => tw.workflow.id == newWorkflowId)?.workflow.procedure
                ?.id
        }
        return uq
    })
    return [...updateWorkflowQuestions]
}
export function findElement(steps: Step[], questionId: string): Element | undefined {
    var foundElement = steps
        .flatMap((step) => (!isConcreteStep(step) ? [] : step.elements))
        .find((element) => element.id === questionId)
    if (foundElement) return foundElement

    return (
        steps
            .flatMap((step) => (isConcreteStep(step) ? step.elements : []))
            .filter((element) => element.type === 'section' || element.type === 'loop')
            // @ts-ignore
            .map((element) => findElement(element.steps, questionId))
            .find((element) => element !== undefined)
    )
}
export function findStep(steps: Step[], stepId: string): Step | undefined {
    var foundStep = steps.find((step) => step.id === stepId)
    if (foundStep) return foundStep

    return (
        steps
            .flatMap((step) => (isConcreteStep(step) ? step.elements : []))
            .flat()
            .filter((element) => element.type === 'section' || element.type === 'loop')
            // @ts-ignore
            .map((element) => findStep(element.steps, stepId))
            .find((element) => element !== undefined)
    )
}
export function updateElement(steps: Step[], newElement: Element) {
    return steps.map((step) => {
        if (isConcreteStep(step))
            step.elements = step.elements.map((element) => {
                if (element.id === newElement.id) return newElement
                if (element.type === 'section' || element.type === 'loop')
                    element.steps = updateElement(element.steps, newElement)
                return element
            })
        return step
    })
}
export function updateText(flow: Step[], originalFlow: Step[]) {
    return flow.map((step) => {
        if (!isConcreteStep(step)) {
            return step
        }
        step.elements.map((element) => {
            const matchingQuestion = findElement(originalFlow, element.id)
            if (matchingQuestion) {
                element.label = matchingQuestion.label

                if (
                    (element.type === 'dropdown' || element.type === 'image-select' || element.type === 'radio') &&
                    (matchingQuestion.type === 'dropdown' ||
                        matchingQuestion.type === 'image-select' ||
                        matchingQuestion.type === 'radio')
                ) {
                    element.choices = element.choices.map((choice) => {
                        const matchingChoice = matchingQuestion.choices.find((oldchoice) => choice.id === oldchoice.id)
                        if (matchingChoice) choice.label = matchingChoice.label
                        return choice
                    })
                }

                if (element.type === 'text-content' && matchingQuestion.type === 'text-content') {
                    matchingQuestion.displayType = element.displayType
                    element = matchingQuestion
                }
            }
            if (element.type === 'section' || element.type === 'loop')
                element.steps = updateText(element.steps, originalFlow)
            return element
        })
        return step
    })
}
export function updateFlow(steps: Step[], newWorkflow: Updates): Step[] {
    return steps.map((step) => {
        if (isConcreteStep(step))
            if (step.guides) {
                // If guides, keep the guides from the flow being updated and remove the guides from the master
                step.guides = []
                if (newWorkflow.flow && newWorkflow.flow.flow) {
                    const existingStep = findStep(newWorkflow.flow.flow.steps, step.id)
                    if (existingStep !== undefined && isConcreteStep(existingStep))
                        if (existingStep && existingStep.guides) {
                            step.guides = existingStep.guides
                        }
                }
            }
        if (isConcreteStep(step))
            step.elements.forEach((element) => {
                const updateUnit = newWorkflow.unitQuestions.find((uq) => uq.questionId === element.id)
                if (element.type === 'loop' && updateUnit !== undefined && updateUnit.newUnitTypeId) {
                    element.unitType = updateUnit.newUnitTypeId
                    element.unitFilters = []
                }
                if (element.type === 'unit-select' && updateUnit !== undefined && updateUnit.newUnitTypeId) {
                    element.unitTypes = [updateUnit.newUnitTypeId]
                }

                if (element.type === 'section' || element.type === 'loop') {
                    element.steps = updateFlow(element.steps, newWorkflow)
                }
                const updateWorkflow = newWorkflow.subtaskQuestions.find((sq) => sq.questionId === element.id)
                if (element.type === 'flow' && updateWorkflow && updateWorkflow.newWorkflowId) {
                    element.workflow = updateWorkflow.newWorkflowId
                    element.procedure = updateWorkflow.newProcedureId
                }
                return element
            })
        return step
    })
}

export async function postImage(
    selectedAccount: Account,
    url: string,
    img: string | Blob,
    fields: any,
    mimeType: string,
    filename: string
) {
    try {
        var bodyFormData = new FormData()
        let file
        if (typeof img === 'string') {
            // Fetch the actual image URL from a service
            const fsurl = await fetch(img.replace('?thumbnail=true', '?doNotRedirect=true'), {
                headers: {
                    authorization: 'Bearer ' + selectedAccount.token,
                },
            }).then((response: any) => response.text())

            // Fetch the image blob from the URL
            file = await fetch(fsurl).then((response: any) => {
                return response.blob()
            })
        } else {
            file = img
        }
        bodyFormData.append('Content-Type', mimeType)
        // Append all fields to FormData
        for (var key in fields) {
            bodyFormData.append(key, fields[key])
        }

        // Append the file to FormData
        // bodyFormData.append("file", new File([file], filename, { type: mimeType }));
        bodyFormData.append('file', file)
        // Send the POST request to S3
        const response = await fetch(url, {
            method: 'POST',
            body: bodyFormData,
            // Don't set Content-Type header manually
        })

        if (!response.ok) {
            throw new Error(`Upload failed with status: ${response.status}`)
        }
    } catch (e) {
        console.error('Error uploading image:', e)
    }
}

async function createMeta(
    selectedAccount: Account,
    file: string,
    fileSizeInBytes: number,
    extension: string,
    fileId: string
) {
    return postApi(
        selectedAccount,
        'api/files/meta',
        JSON.stringify({
            fileName: file,
            fileSizeInBytes: fileSizeInBytes,
            extension: extension,
            fileId: fileId,
            createdAt: new Date().toISOString(),
        })
    )
        .then(function (response: any) {
            return response
        })
        .catch(function (error: any) {})
}
async function getMeta(selectedAccount: Account, url: string) {
    if (!url) return

    return fetch(url.replace('?thumbnail=true', '') + '/meta', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + selectedAccount.token,
        },
    })
        .then((response: any) => {
            return response.json()
        })
        .catch((e) => {
            return
        })
}
async function getUploadUrl(selectedAccount: Account, file: string, mimeType: string, extension: string) {
    return postApi(
        selectedAccount,
        'api/files/generateUploadUrl',
        JSON.stringify({
            fileId: file.replace(extension, ''),
            mimeType: mimeType,
        })
    )
        .then(function (response: any) {
            return response
        })
        .catch(function (error: any) {
            console.log(error, file, mimeType, 'Upload URL missing')
        })
}

export async function uploadImage(selectedAccount: Account, url: string | Blob, meta: any) {
    var newmeta = await createMeta(
        selectedAccount,
        meta.fileName,
        Math.round(meta.fileSizeInBytes),
        meta.extension,
        meta.fileId
    )
    var uploadUrl = await getUploadUrl(
        selectedAccount,
        newmeta.fileId,
        'image/' + meta.extension.replace('.', ''),
        meta.extension
    )
    await postImage(
        selectedAccount,
        uploadUrl.uploadUrl,
        url,
        uploadUrl.fields,
        'image/' + meta.extension.replace('.', ''),
        meta.fileName
    ).catch((e) => {
        console.log('Upload Error', e)
        uploadUrl.thumbnailUrl = 'https://failed.com'
    })
    return uploadUrl.thumbnailUrl
}

export async function replaceImages(selectedAccount: Account, flow: any, convertedurls?: string[]) {
    if (!convertedurls) convertedurls = []
    if (!flow) return flow
    if (typeof flow === 'object')
        for (var key in flow)
            if (typeof flow[key] === 'object')
                flow[key] = await replaceImages(selectedAccount, flow[key], convertedurls)
    if (Array.isArray(flow)) {
        for (var i = 0; i < flow.length; i++) flow[i] = await replaceImages(selectedAccount, flow[i], convertedurls)
        return flow
    }
    if (flow.url && !convertedurls.includes(flow.url)) {
        var imagemeta = await getMeta(selectedAccount, flow.url)
        if (!imagemeta || !imagemeta.extension) {
            convertedurls.push(flow.url)
            return flow
        }

        var imgurl = await uploadImage(selectedAccount, flow.url, imagemeta)
        flow.url = imgurl
        convertedurls.push(imgurl)
    }
    return flow
}

export function compareFlows(first: Step[], second: Step[]) {
    var result = {
        newSteps: [] as Element[],
        missingSteps: [] as Element[],
    }
    first
        .flatMap((step) => (isConcreteStep(step) ? step.elements : []))
        .forEach((element) => {
            var matchToFirst = second
                .flatMap((step) => (isConcreteStep(step) ? step.elements : []))
                .find((secondElement) => element.id === secondElement.id)
            if (!matchToFirst) result.missingSteps.push(element)
            else if (
                (element.type === 'section' && matchToFirst.type === 'section') ||
                (element.type === 'loop' && matchToFirst.type === 'loop')
            ) {
                var matches = compareFlows(element.steps, matchToFirst.steps)
                result.newSteps = [...matches.newSteps]
                result.missingSteps = [...matches.missingSteps]
            }
        })
    second
        .flatMap((step) => (isConcreteStep(step) ? step.elements : []))
        .forEach((element) => {
            var matchToSecond = first
                .flatMap((step) => (isConcreteStep(step) ? step.elements : []))
                .find((secondStep) => element.id === secondStep.id)
            if (!matchToSecond) result.newSteps.push(element)
        })
    return result
}
export function compareWorkflowTags(first: DuplicateFlow['workflow'], second: DuplicateFlow['workflow']) {
    var result = {
        missing: [] as string[],
        added: [] as string[],
    }
    // Find element tags in first not in second
    first.elementTags
        ?.map((tag) => tag.tagName)
        .forEach((tag) => {
            if (!second.elementTags?.map((tag) => tag.tagName).includes(tag)) result.missing.push(tag)
        })
    // Check all tags in second are in first
    second.elementTags
        ?.map((tag) => tag.tagName)
        .forEach((tag) => {
            if (!first.elementTags?.map((tag) => tag.tagName).includes(tag)) result.added.push(tag)
        })
    // Check all tags in first exist in second
    first.tags?.map((tag) => {
        if (!second.tags?.includes(tag)) result.missing.push(tag)
    })
    // Find tags in second not in first
    second.tags?.map((tag) => {
        if (!first.tags?.includes(tag)) result.added.push(tag)
    })
    return result
}
const prefDefaults = {
    viewMode: 'tile',
} as UserPrefs
export function getPref(name: keyof UserPrefs) {
    const prefs = JSON.parse(localStorage.getItem('prefs') || JSON.stringify(prefDefaults)) as UserPrefs
    return prefs[name]
}
export function setPref(name: keyof UserPrefs, value: UserPrefs[typeof name]) {
    const prefs = JSON.parse(localStorage.getItem('prefs') || JSON.stringify(prefDefaults)) as UserPrefs
    prefs[name] = value
    localStorage.setItem('prefs', JSON.stringify(prefs))
    return prefs
}
