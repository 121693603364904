import { Updates, UpdateUpdaterProps, UnitQuestion, DuplicateFlow, DuplicateFlows, Account } from '../../types'
import { useEffect, useState } from 'react'
import type { SyncFlowWorkflow } from '../../../../shared/domain/workflows/types.no-deps'
import { Checkbox, Button, FormControl, TextField } from '@mui/material'
import { EditEndpoint } from '../../../../server/http/workflows/edit.no-deps'
import { GetAllEndpoint as UnitTypesOutput } from '../../../../server/http/unitTypes/getAll.no-deps'
import { MapUnitType, MapWorkflow } from './CopyWizard'
import {
    updateText,
    replaceImages,
    findUnitQuestions,
    setUnitTypeId,
    setWorkflowId,
    updateFlow,
    findElement,
    findSubtaskQuestions,
} from './logic'
import { EyeIcon } from './icons'

export type UpdateWizardProps = {
    duplicateFlows: DuplicateFlow[]
    selectedFlows: DuplicateFlow[]
    instances: Account[]
    selectedFlow: DuplicateFlow
    toWorkflows: DuplicateFlow[]
}

function UpdateWizard(props: UpdateWizardProps) {
    const [fromUnitTypes, setFromUnitTypes] = useState({
        status: 'loading',
        unitTypes: [] as UnitTypesOutput['Output']['unitTypes'],
    })
    const [selectedFlows, setSelectedFlows] = useState(props.selectedFlows as DuplicateFlow[])
    const [currentPage, setCurrentPage] = useState('mapper' as 'mapper' | 'update')
    const [uploadedFlows, setUploadedFlows] = useState([] as DuplicateFlow[])
    useEffect(() => {
        fetch('/getUnitTypes?customerId=' + props.selectedFlow.customerId)
            .then((response) => response.text())
            .then((data) => setFromUnitTypes(JSON.parse(data)))
    }, [props.selectedFlow.customerId])
    if (fromUnitTypes.status === 'loading')
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )
    if (currentPage === 'update') {
        return (
            <div className="ViewFlows">
                {selectedFlows.map((sf) => {
                    var instance = props.instances.find((instance) => instance.customerId === sf.customerId)
                    if (!instance) return <div>Error</div>
                    sf.updated =
                        uploadedFlows.filter(
                            (uf) => uf.customerId === sf.customerId && uf.workflow.id === sf.workflow.id
                        ).length > 0
                    return (
                        <div className="ProcedureSection success">
                            <UpdateFlows
                                updated={(updated: DuplicateFlow) => {
                                    updated.updated =
                                        uploadedFlows.filter(
                                            (uf) =>
                                                uf.customerId === updated.customerId &&
                                                uf.workflow.id === updated.workflow.id
                                        ).length > 0
                                    if (!updated.updated) {
                                        setUploadedFlows([...uploadedFlows, updated])
                                    }
                                }}
                                instance={instance}
                                selectedFlow={sf}
                            />
                        </div>
                    )
                })}
                {selectedFlows.length !== uploadedFlows.length ? (
                    <Button className="big-button disabled" variant="contained" onClick={() => {}} disabled={true}>
                        Uploading
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        className="big-button"
                        onClick={() => {
                            window.location.reload()
                        }}
                        disabled={false}
                    >
                        Done
                    </Button>
                )}
            </div>
        )
    }
    const buttonDisabled = selectedFlows.filter((sf) => sf.updated !== true).length > 0
    return (
        <div className="ViewFlows">
            {selectedFlows.map((selectedFlow) => {
                const fromInstance = props.instances.find(
                    (instance) => instance.customerId === props.selectedFlow.customerId
                )
                const toInstance = props.instances.find((instance) => instance.customerId === selectedFlow.customerId)
                if (!fromInstance || !toInstance) return <div>Error</div>
                return (
                    <UpdateMappings
                        returnFlow={(workflow: DuplicateFlow, valid: boolean) => {
                            workflow.updated = valid
                            setSelectedFlows(
                                selectedFlows.map((sf) => {
                                    if (
                                        sf.customerId === selectedFlow.customerId &&
                                        sf.workflow.id === selectedFlow.workflow.id
                                    )
                                        return workflow
                                    return sf
                                })
                            )
                        }}
                        fromInstance={fromInstance}
                        toInstance={toInstance}
                        toWorkflows={props.toWorkflows.filter((df) => df.customerId === toInstance.customerId)}
                        fromUnitTypes={fromUnitTypes.unitTypes}
                        fromDuplicateFlow={props.selectedFlow}
                        toDuplicateFlow={selectedFlow}
                        originalToFlow={props.selectedFlows.find(
                            (sf) =>
                                sf.customerId === selectedFlow.customerId && sf.workflow.id === selectedFlow.workflow.id
                        )}
                    />
                )
            })}
            <Button
                variant="contained"
                className={'big-button' + (buttonDisabled ? ' disabled' : '')}
                onClick={() => {
                    setCurrentPage('update')
                }}
                disabled={buttonDisabled}
            >
                Update
            </Button>
        </div>
    )
}
type UpdateMappingsTypes = {
    fromUnitTypes: UnitTypesOutput['Output']['unitTypes']
    fromDuplicateFlow: DuplicateFlow
    toDuplicateFlow: DuplicateFlow
    originalToFlow?: DuplicateFlow
    fromInstance: Account
    toInstance: Account
    toWorkflows: DuplicateFlow[]
    returnFlow: (workflow: DuplicateFlow, valid: boolean) => void
}
function UpdateMappings(props: UpdateMappingsTypes) {
    const [toUnitTypes, setToUnitTypes] = useState({
        status: 'loading',
        unitTypes: [] as UnitTypesOutput['Output']['unitTypes'],
    })
    const [newWorkflow, setNewWorkflow] = useState<Updates>({
        name: props.toDuplicateFlow.workflow.name,
        unitQuestions: props.toDuplicateFlow.workflow.flow
            ? findUnitQuestions(props.toDuplicateFlow.workflow.flow.steps, [])
            : [],
        subtaskQuestions: props.toDuplicateFlow.workflow.flow
            ? findSubtaskQuestions(props.toDuplicateFlow.workflow.flow.steps, [])
            : [],
        procedureId: props.toDuplicateFlow.workflow.procedure?.id,
        keepText: false,
        flow: props.toDuplicateFlow.workflow,
    })

    useEffect(() => {
        fetch('/getUnitTypes?customerId=' + props.toDuplicateFlow.customerId)
            .then((response) => response.text())
            .then((data) => {
                setToUnitTypes(JSON.parse(data))
            })
    }, [props.toDuplicateFlow.customerId])

    newWorkflow.unitQuestions = newWorkflow.unitQuestions.map((uq) => {
        if (uq.newUnitTypeId || !props.toDuplicateFlow.workflow.flow?.steps) return uq
        // Pre-set unit type for matching questions
        var matchedQuestion = findElement(props.toDuplicateFlow.workflow.flow?.steps, uq.questionId)

        if (matchedQuestion && matchedQuestion.type === 'unit-select')
            uq.newUnitTypeId = matchedQuestion.unitTypes.pop()

        if (matchedQuestion && matchedQuestion.type === 'loop') uq.newUnitTypeId = matchedQuestion.unitType

        return uq
    })
    newWorkflow.subtaskQuestions = newWorkflow.subtaskQuestions.map((sq) => {
        if (sq.newWorkflowId || !props.toDuplicateFlow.workflow.flow?.steps) return sq
        var matchedQuestion = findElement(props.toDuplicateFlow.workflow.flow?.steps, sq.questionId)

        if (matchedQuestion && matchedQuestion.type === 'flow') {
            sq.newWorkflowId = matchedQuestion.workflow
            sq.newProcedureId = matchedQuestion.procedure
        }
        return sq
    })

    // Check if all fields are filled
    const isComplete =
        newWorkflow.name &&
        newWorkflow.name.length > 0 &&
        newWorkflow.procedureId &&
        newWorkflow.unitQuestions.filter((uq) => !uq.newUnitTypeId).length === 0

    if (isComplete && newWorkflow.procedureId) {
        let duplicateflow = JSON.parse(JSON.stringify(props.fromDuplicateFlow)) as DuplicateFlow
        duplicateflow.workflow.name = newWorkflow.name
        duplicateflow.workflow.id = props.toDuplicateFlow.workflow.id
        duplicateflow.customerId = props.toDuplicateFlow.customerId
        duplicateflow.workflow.procedure = props.toDuplicateFlow.workflow.procedure

        if (!duplicateflow.workflow.flow || !props.toDuplicateFlow.workflow.flow) return <div>Error</div>

        duplicateflow.workflow.flow.steps = updateFlow(duplicateflow.workflow.flow.steps, newWorkflow)

        if (newWorkflow.keepText && props.originalToFlow && props.originalToFlow.workflow.flow)
            if (duplicateflow?.workflow?.flow?.steps) {
                updateText(duplicateflow.workflow.flow.steps, props.originalToFlow.workflow.flow.steps)
            } else {
                console.error('Steps not found')
            }
        if (props.toDuplicateFlow.workflow.flow)
            duplicateflow.workflow.flow.subjectType = props.toDuplicateFlow.workflow.flow.subjectType
        if (
            JSON.stringify(props.toDuplicateFlow.workflow.flow) !== JSON.stringify(duplicateflow.workflow.flow) ||
            !props.toDuplicateFlow.updated
        ) {
            props.returnFlow(duplicateflow, true)
        }
    }

    if (toUnitTypes.status === 'loading')
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )
    function getQuestionLabel(question: UnitQuestion) {
        switch (question.questionType) {
            case 'loop':
                return 'Loop Element: ' + question.questionLabel
            case 'unit-select':
                return 'Unit Select Element: ' + question.questionLabel
            case 'subject':
                return question.questionLabel
            default:
                return 'Unit Filter For: ' + question.questionLabel
        }
    }

    return (
        <div className="ProcedureSection">
            <div className="row">
                <div className="column">
                    <h2>
                        Copy from <b>{props.fromInstance.customerName}</b> to...
                    </h2>
                </div>
                <div className="column">
                    <h2>
                        <b>{props.toInstance.customerName}</b>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p>
                        Name Flow
                        <sub>Name must be unique to the procedure</sub>
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                        <TextField
                            onChange={(event) => {
                                setNewWorkflow({
                                    unitQuestions: newWorkflow.unitQuestions,
                                    subtaskQuestions: newWorkflow.subtaskQuestions,
                                    name: event.target.value,
                                    procedureId: newWorkflow.procedureId,
                                    keepText: newWorkflow.keepText,
                                    flow: newWorkflow.flow,
                                })
                            }}
                            value={newWorkflow.name}
                            variant="outlined"
                        />
                    </FormControl>
                </div>
            </div>
            {newWorkflow.unitQuestions.map((unitQuestion) => {
                return (
                    <MapUnitType
                        fromUnit={
                            props.fromUnitTypes
                                ? props.fromUnitTypes.find((unitType) => {
                                      return unitType.id === unitQuestion.unitTypeId
                                  })
                                : undefined
                        }
                        toUnitTypes={toUnitTypes.unitTypes}
                        prompt={getQuestionLabel(unitQuestion)}
                        handleChange={(event: any) => {
                            setNewWorkflow({
                                unitQuestions: setUnitTypeId(event.target.value as number, unitQuestion, newWorkflow),
                                subtaskQuestions: newWorkflow.subtaskQuestions,
                                name: newWorkflow.name,
                                procedureId: newWorkflow.procedureId,
                                keepText: newWorkflow.keepText,
                                flow: newWorkflow.flow,
                            })
                        }}
                        preselectUnitType={unitQuestion.newUnitTypeId}
                    />
                )
            })}
            {newWorkflow.subtaskQuestions.map((subtaskQuestion) => {
                return (
                    <MapWorkflow
                        fromWorkflow={{
                            id: subtaskQuestion.workflowId,
                            name: subtaskQuestion.questionLabel,
                        }}
                        toWorkflows={props.toWorkflows}
                        prompt={'Update Workflow'}
                        handleChange={(event: any) => {
                            setNewWorkflow({
                                unitQuestions: newWorkflow.unitQuestions,
                                subtaskQuestions: setWorkflowId(
                                    parseInt(event.target.value) as number,
                                    subtaskQuestion,
                                    newWorkflow,
                                    props.toWorkflows
                                ),
                                name: newWorkflow.name,
                                procedureId: newWorkflow.procedureId,
                            })
                        }}
                        preselectWorkflow={subtaskQuestion.newWorkflowId}
                    />
                )
            })}
            <div className="row">
                <div className="column">
                    <p>
                        Keep translations?
                        <sub>Check this box to keep any translations</sub>
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                        <Checkbox
                            onChange={(event) => {
                                setNewWorkflow({
                                    unitQuestions: newWorkflow.unitQuestions,
                                    subtaskQuestions: newWorkflow.subtaskQuestions,
                                    name: newWorkflow.name,
                                    procedureId: newWorkflow.procedureId,
                                    keepText: event.target.checked,
                                    flow: newWorkflow.flow,
                                })
                            }}
                            className="center"
                            value={newWorkflow.keepText}
                        />
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export function UpdateFlows(props: UpdateUpdaterProps) {
    const [updatedWorkflow, setUpdatedWorkflow] = useState({
        status: props.selectedFlow.updated ? 'success' : 'loading',
        response: {} as EditEndpoint['Output'],
    })
    useEffect(() => {
        if (!props.selectedFlow.updated)
            replaceImages(props.instance, props.selectedFlow.workflow).then((newFlow: SyncFlowWorkflow) => {
                fetch('/updateFlow?customerId=' + props.instance.customerId, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newFlow),
                })
                    .then((response) => response.text())
                    .then((data) =>
                        setUpdatedWorkflow(JSON.parse(data) as { status: string; response: EditEndpoint['Output'] })
                    )
            })
    }, [props.instance, props.selectedFlow.workflow])
    if (updatedWorkflow.status === 'loading') return <div className="pulseLoader"></div>
    if (updatedWorkflow.status === 'fail' || !updatedWorkflow.response) return <div>An error has occured</div>
    // @ts-ignore
    if (updatedWorkflow.response && updatedWorkflow.response.message === 'User is not a collaborator')
        return <li>Missing collaborator or procedure admin permission</li>

    props.updated(props.selectedFlow)

    return (
        <li>
            <p>
                ✅ {props.selectedFlow.workflow.name}
                <sub>
                    Flow updated successfully! Now you can add schedules, statuses, filters and other instance level
                    features by pressing the preview icon
                </sub>
            </p>
            {props.selectedFlow.workflow.procedure && props.selectedFlow.workflow.procedure.id ? (
                <a
                    rel="noreferrer"
                    href={`https://${props.instance.serverURL}/procedures/${props.selectedFlow.workflow.procedure?.id}/tasks/workflows/${props.selectedFlow.workflow.id}/edit`}
                    target="_blank"
                >
                    <button>
                        <EyeIcon />
                    </button>
                </a>
            ) : (
                ''
            )}
        </li>
    )
}
export default UpdateWizard
