import { DuplicateFlow, DuplicateFlows, Account, ExternalConnection } from '../../types'
import { useState } from 'react'
import { getPref, setPref } from './logic'
import { Box, Tabs, Tab, TextField, Button } from '@mui/material'
import { RenderTile, DisplayModeToggle, PillSelect } from './SharedComponents'
import CopyWizard from './CopyWizard'
import { ViewCopies } from './ViewCopies'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { TaskIcon, TrainingIcon, GuideIcon, CopiesIcon, CompareIcon, EyeIcon } from './icons'
import { App as ParsableApp } from './connectors/parsable/App'
type page = 'home' | 'compare' | 'update'
type DisplayFlow = {
    isShared: boolean
    flows: DuplicateFlow[]
    instances: number
    type: 'task' | 'training' | 'guide'
    name: string
    procedure: string
    buttons: JSX.Element[]
}
type DisplayFlowProps = {
    setSelectedFlow: (duplicateFlow?: DuplicateFlow[]) => void
    setCurrentPage: (page: page) => void
    whichFlows: 'everything' | 'shared'
    accounts: Account[]
    displayFlows: DisplayFlow[]
    selectedInstance?: Account
}
type RightPanelProps = {
    instances: Account[]
    duplicateFlows: DuplicateFlows
    selectedInstance?: Account | ExternalConnection
    setSelectedFlow: (duplicateFlow?: DuplicateFlow[]) => void
    selectedFlow?: DuplicateFlow[]
}
export function RightPanel(props: RightPanelProps) {
    const [currentPage, setCurrentPage] = useState<page>('home')
    const [search, setSearch] = useState<string>('')
    const [viewMode, setViewMode] = useState<'tile' | 'row'>(getPref('viewMode'))
    const [whichFlows, setWhichFlows] = useState<'everything' | 'shared'>('everything')
    const [duplicateFlows, setDuplicateFlows] = useState(props.duplicateFlows)
    const [filteredTags, setFilteredTags] = useState<string[]>([])
    const [filteredFlowType, setFilteredFlowType] = useState<string[]>([])
    const [filteredProcedures, setFilteredProcedures] = useState<string[]>([])
    setPref('viewMode', viewMode)

    function isConnection(instance: Account | ExternalConnection): instance is ExternalConnection {
        return (instance as ExternalConnection).type !== undefined
    }
    if (props.selectedInstance && isConnection(props.selectedInstance)) {
        console.log('Loading app')
        return (
            <div>
                <ParsableApp
                    instances={props.instances}
                    duplicateFlows={props.duplicateFlows}
                    account={props.selectedInstance}
                />
            </div>
        )
    }

    if (props.selectedFlow) {
        const hostAccount = props.instances.find(
            (instance) => props.selectedFlow && instance.customerId === props.selectedFlow[0].customerId
        )
        return (
            <div>
                <div className="row">
                    <div className="breadcrumb">
                        <a onClick={() => props.setSelectedFlow(undefined)}>
                            <sub>{props.selectedInstance ? props.selectedInstance.customerName : 'All'}</sub>
                        </a>
                        <sub> / {props.selectedFlow[0].workflow.name}</sub>
                    </div>
                </div>
                {props.selectedFlow && props.selectedFlow.length > 1 ? (
                    <>
                        <h2>Live Versions ({props.selectedFlow.length})</h2>
                        <ViewCopies
                            currentPage={currentPage}
                            setPage={setCurrentPage}
                            instances={props.instances}
                            duplicateFlows={props.selectedFlow}
                            setFlow={props.setSelectedFlow}
                            toWorkflows={props.duplicateFlows}
                        />
                    </>
                ) : hostAccount ? (
                    <CopyWizard
                        instances={props.instances.filter(
                            (instance) =>
                                props.selectedFlow &&
                                !props.selectedFlow.some((sf) => sf.customerId === instance.customerId)
                        )}
                        selectedWorkflow={props.selectedFlow[0].workflow}
                        selectedAccount={hostAccount}
                        toWorkflows={Object.values(props.duplicateFlows).flat()}
                    />
                ) : null}
            </div>
        )
    }
    const filteredRows = Object.values(duplicateFlows)
        .map((df: DuplicateFlow[]): DisplayFlow => {
            return {
                isShared: new Set(df.map((fl) => fl.customerId)).size > 1,
                flows: df.filter((flow) => {
                    if (
                        search.length > 0 &&
                        (!flow.workflow.name || !flow.workflow.name.toLowerCase().includes(search.toLowerCase()))
                    )
                        return false
                    if (
                        props.selectedInstance &&
                        !isConnection(props.selectedInstance) &&
                        flow.customerId !== props.selectedInstance.customerId
                    )
                        return false
                    return true
                }),
                instances: df.length,
                type: df[0].workflow.type,
                name: df[0].workflow.name,
                procedure: df[0].workflow.procedure?.name || 'Shared',
                buttons:
                    df.length === 1
                        ? [
                              <Button onClick={() => props.setSelectedFlow(df)}>
                                  <CopiesIcon />
                                  Copy
                              </Button>,
                              df[0].workflow.procedure ? (
                                  <a
                                      rel="noreferrer"
                                      href={`https://${props.instances.find((i) => i.customerId === df[0].customerId)
                                          ?.serverURL}/procedures/${df[0].workflow.procedure.id}/tasks/workflows/${
                                          df[0].workflow.id
                                      }/edit`}
                                      target="_blank"
                                  >
                                      <Button className="icon-only">
                                          <EyeIcon />
                                      </Button>
                                  </a>
                              ) : (
                                  <></>
                              ),
                          ]
                        : [
                              <Button onClick={() => props.setSelectedFlow(df)}>
                                  <CompareIcon />
                                  Compare
                              </Button>,
                          ],
            }
        })
        .filter((flow: DisplayFlow) => {
            return flow.flows.length > 0
        })

    const tags = Object.values(props.duplicateFlows)
        .flat()
        .map((df) => df.workflow.tags)
        .flat()
        .filter((item, index, array) => array.indexOf(item) === index) // Removed duplicates
        .filter((tag) => tag !== undefined) as string[]
    const procedures = Object.values(props.duplicateFlows)
        .flat()
        .map((df) => df.workflow.procedure?.name)
        .filter((procedure) => procedure !== undefined)
        .filter((item, index, array) => array.indexOf(item) === index) as string[]

    const filterFunction = (flow: DisplayFlow) => {
        return (whichFlows === 'everything' || flow.isShared) && filterFunctionTags(flow)
    }
    const filterFunctionTags = (flow: DisplayFlow) => {
        return (
            (filteredTags.length === 0 ||
                flow.flows
                    .map((wf) => {
                        return wf.workflow.tags
                            ?.map((tag) => {
                                console.log(wf.workflow.tags, tag)
                                return filteredTags.includes(tag)
                            })
                            .includes(true)
                    })
                    .includes(true)) &&
            (filteredFlowType.length === 0 ||
                flow.flows
                    .map((wf) => {
                        return filteredFlowType.map((type) => type.toLowerCase()).includes(wf.workflow.type)
                    })
                    .includes(true)) &&
            (filteredProcedures.length === 0 ||
                flow.flows
                    .map((wf) => {
                        if (!wf.workflow.procedure) return false
                        return filteredProcedures.includes(wf.workflow.procedure.name)
                    })
                    .includes(true))
        )
    }
    return (
        <div className="HomePage">
            {Object.keys(props.duplicateFlows).length > 0 ? (
                <>
                    <div className="row">
                        <div className="column">
                            <div className="breadcrumb">
                                <sub>{props.selectedInstance ? props.selectedInstance.customerName : 'All'}</sub>
                            </div>
                        </div>
                        <div className="column">
                            <TextField
                                className="search"
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                placeholder="Search"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={whichFlows}
                                onChange={(e, value) => {
                                    setWhichFlows(value)
                                    setDuplicateFlows(duplicateFlows)
                                }}
                            >
                                <Tab
                                    label={'Everything (' + filteredRows.filter(filterFunctionTags).length + ')'}
                                    value="everything"
                                />
                                <Tab
                                    label={
                                        'Shared (' +
                                        filteredRows.filter(filterFunctionTags).filter((fr) => fr.isShared).length +
                                        ')'
                                    }
                                    value="shared"
                                />
                            </Tabs>
                        </Box>
                        <DisplayModeToggle viewMode={viewMode} onChange={setViewMode} />
                    </div>
                    <div className="row">
                        <div>
                            <PillSelect
                                label="Tags"
                                options={tags}
                                selectedOptions={filteredTags}
                                setSelection={setFilteredTags}
                            />
                            <PillSelect
                                label="Type"
                                options={['Task', 'Training', 'Guide']}
                                selectedOptions={filteredFlowType}
                                setSelection={setFilteredFlowType}
                            />
                            <PillSelect
                                label="Procedure"
                                options={procedures}
                                selectedOptions={filteredProcedures}
                                setSelection={setFilteredProcedures}
                            />
                        </div>
                    </div>
                    {viewMode === 'tile' ? (
                        <RenderFlowTiles
                            whichFlows={whichFlows}
                            setSelectedFlow={props.setSelectedFlow}
                            setCurrentPage={setCurrentPage}
                            selectedInstance={props.selectedInstance}
                            accounts={props.instances}
                            displayFlows={filteredRows.filter(filterFunction)}
                        />
                    ) : (
                        ''
                    )}
                    {viewMode === 'row' ? (
                        <RenderFlowTable
                            whichFlows={whichFlows}
                            setSelectedFlow={props.setSelectedFlow}
                            setCurrentPage={setCurrentPage}
                            selectedInstance={props.selectedInstance}
                            accounts={props.instances}
                            displayFlows={filteredRows.filter(filterFunction)}
                        />
                    ) : (
                        ''
                    )}
                </>
            ) : (
                <div className="ViewFlows">
                    <div className="Error">
                        <h1>Copy Flows</h1>
                        <p>Add an instance on the left and copy flows to build a library</p>
                    </div>
                </div>
            )}
        </div>
    )
}

function RenderFlowTiles(props: DisplayFlowProps) {
    const sections: {
        header: string
        sectionId: number
        elements: JSX.Element[]
    }[] = []

    props.displayFlows.forEach((instance) => {
        let section: (typeof sections)[0]
        if (props.selectedInstance) {
            section = sections.find((section) => section.sectionId === instance.flows[0].workflow.procedure?.id) || {
                header: instance.flows[0].workflow.procedure?.name || 'Other',
                sectionId: instance.flows[0].workflow.procedure?.id || 0,
                elements: [] as JSX.Element[],
            }
        } else {
            // If shared between more than 1 instance
            if (instance.isShared) {
                section = sections.find((section) => section.sectionId === 0) || {
                    header: 'Shared',
                    sectionId: 0,
                    elements: [] as JSX.Element[],
                }
            } else {
                section = sections.find((section) => section.sectionId === instance.flows[0].customerId) || {
                    header:
                        props.accounts.find((a) => a.customerId === instance.flows[0].customerId)?.customerName ||
                        'Shared',
                    sectionId: instance.flows[0].customerId,
                    elements: [] as JSX.Element[],
                }
            }
        }
        section.elements.push(
            <RenderTile
                flow={instance.flows[0].workflow}
                onClick={() => {
                    props.setSelectedFlow(instance.flows)
                    props.setCurrentPage('compare')
                }}
                image={instance.flows[0].image}
                key={instance.flows[0].workflow.id}
                count={instance.instances}
                customerId={instance.flows[0].customerId}
                buttons={instance.buttons}
            />
        )
        if (!sections.find((sct) => sct.sectionId === section.sectionId)) sections.push(section)
    })

    return (
        <>
            {sections
                .sort((a, b) => a.sectionId - b.sectionId)
                .map((section) => {
                    return (
                        <div className="ProcedureSection" key={section.sectionId}>
                            <h2>{section.header}</h2>
                            {section.elements}
                        </div>
                    )
                })}
        </>
    )
}

function RenderFlowTable(props: DisplayFlowProps) {
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
    const [sortColumn, setSortColumn] = useState<'procedure' | 'name' | 'version' | 'id' | null>(null)

    const handleSort = (column: 'procedure' | 'name' | 'version' | 'id' | null) => {
        const isAsc = sortColumn === column && sortDirection === 'asc'
        setSortDirection(isAsc ? 'desc' : 'asc')
        setSortColumn(column)
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={sortColumn === 'id'}
                                direction={sortDirection}
                                onClick={() => handleSort('id')}
                            >
                                ID
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'name'}
                                direction={sortDirection}
                                onClick={() => handleSort('name')}
                            >
                                Name
                            </TableSortLabel>
                        </TableCell>

                        <TableCell>
                            <TableSortLabel
                                active={sortColumn === 'procedure'}
                                direction={sortDirection}
                                onClick={() => handleSort('procedure')}
                            >
                                Procedure
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel
                                active={sortColumn === 'version'}
                                direction={sortDirection}
                                onClick={() => handleSort('version')}
                            >
                                Version
                            </TableSortLabel>
                        </TableCell>
                        <TableCell width={150}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.displayFlows
                        .map((instance) => {
                            return {
                                type: instance.type,
                                name: instance.name,
                                procedure: instance.procedure,
                                instances: instance.instances,
                                version: instance.flows[0].workflow.numberOfVersions,
                                id: instance.instances > 1 ? null : instance.flows[0].workflow.id,
                                buttons: instance.buttons,
                            }
                        })
                        .sort((a, b) => {
                            if (!sortColumn) return 0
                            if (sortColumn === null || a[sortColumn] === null || b[sortColumn] === null) return 0
                            let first =
                                typeof a[sortColumn] === 'string'
                                    ? (a[sortColumn] as string).toLowerCase()
                                    : a[sortColumn]
                            let second =
                                typeof b[sortColumn] === 'string'
                                    ? (b[sortColumn] as string).toLowerCase()
                                    : b[sortColumn]
                            if (first === null || second === null) return 0
                            if (sortDirection === 'asc') {
                                if (first < second) return -1
                                else if (first > second) return 1
                            }
                            if (sortDirection === 'desc') {
                                if (first > second) return -1
                                else if (first < second) return 1
                            }
                            return 0
                        })
                        .map((row, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell
                                    width="20px"
                                    component="th"
                                    align="center"
                                    scope="row"
                                    className={'flowtype ' + row.type}
                                >
                                    {row.type === 'task' ? (
                                        <TaskIcon />
                                    ) : row.type === 'training' ? (
                                        <TrainingIcon />
                                    ) : (
                                        <GuideIcon />
                                    )}
                                </TableCell>
                                <TableCell align="center">{row.instances > 1 ? null : row.id}</TableCell>
                                <TableCell align="left">
                                    <div className="row-text-crop">{row.name}</div>
                                    {row.instances > 1 ? (
                                        <sub>
                                            <div className="count">
                                                <CopiesIcon />
                                            </div>
                                            {row.instances} instances
                                        </sub>
                                    ) : (
                                        <></>
                                    )}
                                </TableCell>
                                <TableCell align="left">
                                    <div className="row-text-crop">{row.procedure}</div>
                                </TableCell>
                                <TableCell align="center">{row.version}</TableCell>
                                <TableCell width={150} align="right">
                                    <div className="row-buttons">{row.buttons}</div>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
