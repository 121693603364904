import type { SyncFlowWorkflow } from '../../../../shared/domain/workflows/types.no-deps'
import { TaskIcon, TrainingIcon, GuideIcon, TileIcon, RowIcon, CopiesIcon } from './icons'
import { useEffect, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'

type TileProps = {
    flow: SyncFlowWorkflow
    image?: string
    onClick: Function
    count?: number
    buttons?: React.ReactNode[]
    customerId: number
}
export function RenderTile(props: TileProps) {
    let image
    if (props.image) image = <RenderFlowImage flow={props.flow} url={props.image} customerId={props.customerId} />
    else image = <RenderFlowIcon flow={props.flow} />
    return (
        <div className="tile">
            {props.buttons ? <div className="tile-buttons">{props.buttons}</div> : ''}
            <div className="coverimage" onClick={(e) => props.onClick(e)}>
                {image}
            </div>
            <div className="info">
                <div className="info-row title" onClick={(e) => props.onClick(e)}>
                    {props.flow.name}
                </div>
                {props.count !== undefined && props.count > 1 ? (
                    <div className="info-row">
                        <div className="count">
                            <CopiesIcon />
                        </div>
                        {props.count} instances
                    </div>
                ) : (
                    <div className="info-row">
                        <sub>Version {props.flow.numberOfVersions}</sub>
                    </div>
                )}
            </div>
        </div>
    )
}
type FlowImageProps = {
    url: string
    customerId: number
    flow: SyncFlowWorkflow
}
export function RenderFlowImage(props: FlowImageProps) {
    const [url, setUrl] = useState<string>()
    useEffect(() => {
        fetch('/getImage?customerId=' + props.customerId, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                image: props.url,
            }),
        })
            .then((response) => response.text())
            .then((data) => {
                let response = JSON.parse(data)
                if (response.status === 'success') setUrl(response.response)
            })
    }, [props.url, props.customerId])

    if (!url) return <RenderFlowIcon flow={props.flow} />

    return (
        <img
            src={url ? url : './question-mark-grey.svg'}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src = './question-mark-grey.svg'
                currentTarget.className = 'icon'
            }}
            alt=""
        ></img>
    )
}
type FlowIconProps = {
    flow: SyncFlowWorkflow
}
function RenderFlowIcon(props: FlowIconProps) {
    switch (props.flow.type) {
        case 'task':
            return (
                <div className="flowtype task">
                    <TaskIcon />
                </div>
            )
        case 'training':
            return (
                <div className="flowtype training">
                    <TrainingIcon />
                </div>
            )
        case 'guide':
            return (
                <div className="flowtype guide">
                    <GuideIcon />
                </div>
            )
    }
}

type RowProps = {
    flow: SyncFlowWorkflow
    image?: string
    count?: number
    buttons: React.ReactNode[]
}
export function RenderRow(props: RowProps) {
    return (
        <li className={props.flow.type}>
            <RenderFlowIcon flow={props.flow} />
            {props.count ? (
                <div className="count">
                    <div>{props.count}</div>
                </div>
            ) : (
                ''
            )}
            <p>
                {props.flow.name}
                <sub>Version {props.flow.numberOfVersions}</sub>
            </p>
            {props.buttons}
        </li>
    )
}
type DisplayModeTypes = {
    viewMode: 'tile' | 'row'
    onChange: Function
}
export function DisplayModeToggle(props: DisplayModeTypes) {
    return (
        <div className="header-buttons">
            <button
                onClick={(e) => {
                    props.onChange('tile')
                }}
                className={props.viewMode === 'tile' ? 'selected' : ''}
            >
                <TileIcon />
            </button>
            <button
                onClick={(e) => {
                    props.onChange('row')
                }}
                className={props.viewMode === 'row' ? 'selected' : ''}
            >
                <RowIcon />
            </button>
        </div>
    )
}
type PillSelectTypes = {
    options: string[]
    selectedOptions: string[]
    setSelection: (selection: string[]) => void
    label: string
}
export function PillSelect(props: PillSelectTypes) {
    return (
        <FormControl className="pill-select">
            <Select
                labelId="flow-type-filter-label"
                id="flow-type-filter"
                multiple
                displayEmpty
                disabled={props.options.length === 0}
                value={props.selectedOptions}
                className={props.selectedOptions.length > 0 ? 'selected' : ''}
                onChange={(event: SelectChangeEvent<string[]>) => {
                    const {
                        target: { value },
                    } = event
                    props.setSelection(
                        // On autofill we get a stringified value.
                        typeof value === 'string' ? value.split(',') : value
                    )
                }}
                renderValue={(selected) => props.label + (selected.length > 0 ? ' +' + selected.length : '')}
            >
                {props.options.sort().map((option) => (
                    <MenuItem sx={{ maxWidth: 400 }} className="pill-select-menu" key={option} value={option}>
                        <Checkbox checked={props.selectedOptions.includes(option)} />
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
