import { useEffect, useState } from 'react'
import './App.css'
import type { Account, DuplicateFlows, DuplicateFlow, ExternalConnection } from '../../types'
import { DeleteIcon, AllIcon } from './icons'
import { RightPanel } from './RightPanel'
import { TextField } from '@mui/material'
import { Auth as ParsableAuth } from './connectors/parsable/Auth'
function App() {
    const [message, setMessage] = useState<Account[]>()
    const [selectedInstance, setInstance] = useState<Account | ExternalConnection>()
    const [search, setSearch] = useState<string>('')
    const [duplicateFlows, setDuplicateFlows] = useState<DuplicateFlows>()
    const [selectedFlow, setSelectedFlow] = useState<DuplicateFlow[]>()
    useEffect(() => {
        fetch('/list')
            .then((response) => response.text())
            .then((data) => {
                let accounts: Account[]
                try {
                    accounts = JSON.parse(data) as Account[]
                } catch (e) {
                    accounts = []
                }
                setMessage(accounts)
                return accounts
            })
            .then((accounts: Account[]) => {
                const query = new URLSearchParams(window.location.search)
                const customerId = query.get('customerId')
                if (customerId && !isNaN(parseInt(customerId))) {
                    const account = accounts.find(
                        (account) => parseInt(account.customerId as unknown as string) === parseInt(customerId)
                    )
                    if (account) setInstance(account)
                }
                return accounts
            })
            .then((accounts) => {
                if (accounts.length > 0)
                    fetch('/getAllFlows')
                        .then(async (response) => (response.status !== 500 ? JSON.parse(await response.text()) : []))
                        .then(async (data) => {
                            setDuplicateFlows(data)
                        })
                else setDuplicateFlows({})
            })
    }, [])

    var accounts
    if (!message) return <></>

    accounts = message.map((account) => {
        account.isLibrary =
            account.customerSlug.indexOf('global') > -1 ||
            account.customerSlug.indexOf('library') > -1 ||
            account.customerName.toLowerCase().indexOf('library') > -1 ||
            account.customerName.toLowerCase().indexOf('global') > -1
        return account
    })
    if (accounts.length === 0) {
        window.location.href = '/add'
        return <></>
    }
    function isConnection(instance: Account | ExternalConnection): instance is ExternalConnection {
        return (instance as ExternalConnection).type !== undefined
    }
    const addConnection = new URLSearchParams(window.location.search).get('addConnection')
    if (addConnection) {
        if (addConnection === 'parsable') return <ParsableAuth />
    }
    const connections = JSON.parse(localStorage.getItem('external_connections') || '[]') as ExternalConnection[]
    return (
        <div className="App">
            <body className="App-body">
                <div className="left-panel">
                    <TextField
                        label={'Search ' + accounts.length + ' Connections'}
                        className="search"
                        onChange={(e: any) => {
                            setSearch(e.target.value)
                        }}
                        placeholder="Search"
                    />
                    <ul>
                        <li
                            className={!selectedInstance ? 'selected' : ''}
                            onClick={() => {
                                const newUrl = new URL(window.location.href)
                                newUrl.searchParams.delete('customerId')
                                window.history.pushState(null, '', newUrl.toString())
                                setInstance(undefined)
                            }}
                        >
                            <AllIcon /> All
                        </li>
                        {accounts
                            .sort(function (a, b) {
                                var aname = (a.isLibrary ? 'a' : 'b') + a.customerName.toLowerCase()
                                var bname = (b.isLibrary ? 'a' : 'b') + b.customerName.toLowerCase()
                                if (aname < bname) {
                                    return -1
                                }
                                if (aname > bname) {
                                    return 1
                                }
                                return 0
                            })
                            .filter((server) => {
                                return (
                                    search === '' ||
                                    server.customerName.toLowerCase().indexOf(search.toLowerCase()) > -1
                                )
                            })
                            .map((server) => (
                                <li
                                    className={
                                        (selectedInstance &&
                                        !isConnection(selectedInstance) &&
                                        server.customerId === selectedInstance.customerId
                                            ? 'selected '
                                            : '') + (server.isLibrary ? 'library' : '')
                                    }
                                    onClick={() => {
                                        setInstance(server)
                                        setSelectedFlow(undefined)
                                        const newUrl = new URL(window.location.href)
                                        newUrl.searchParams.set('customerId', server.customerId.toString())
                                        window.history.pushState(null, '', newUrl.toString())
                                    }}
                                >
                                    {server.customerName}
                                    <a
                                        onClick={() => {
                                            fetch('/delete?customerId=' + server.customerId)
                                                .then((response) => response.text())
                                                .then((data) => {
                                                    setMessage(JSON.parse(data))
                                                    setInstance(undefined)
                                                })
                                        }}
                                    >
                                        <div className="delete">
                                            <DeleteIcon />
                                        </div>
                                    </a>
                                </li>
                            ))}
                        {connections.map((connection) => (
                            <li
                                className="connection"
                                onClick={() => {
                                    setInstance(connection)
                                    setSelectedFlow(undefined)
                                }}
                            >
                                {'Parsable (' + connection.auth.currentUser.name + ')'}
                                <a
                                    onClick={() => {
                                        localStorage.setItem(
                                            'external_connections',
                                            JSON.stringify(connections.filter((cn) => cn !== connection))
                                        )
                                        window.location.reload()
                                    }}
                                >
                                    <div className="delete">
                                        <DeleteIcon />
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                    <a href="/add">
                        <div className="add-new">Add</div>
                    </a>
                </div>
                <div className="right-panel">
                    {!duplicateFlows ? (
                        <div className="ViewFlows">
                            <div className="loader">
                                <img alt="Loading" src="./progress.gif"></img>
                                <sub>Building your library</sub>
                            </div>
                        </div>
                    ) : (
                        <RightPanel
                            selectedFlow={selectedFlow}
                            setSelectedFlow={setSelectedFlow}
                            instances={accounts}
                            selectedInstance={selectedInstance}
                            duplicateFlows={duplicateFlows}
                        />
                    )}
                </div>
            </body>
        </div>
    )
}

export default App
