import { useEffect, useState } from 'react'
import './style.css'
import Button from '@mui/material/Button'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { login } from './logic'
import { ExternalConnection } from '../../../../types'
export function Auth() {
    const [showPassword, setShowPassword] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [response, setResponse] = useState<any>()

    const buttonDisabled = email.length === 0 || email.indexOf('@') === -1 || password.length === 0
    if (response && response.result?.success) {
        const connections = JSON.parse(localStorage.getItem('external_connections') || '[]') as ExternalConnection[]
        let existingConnection = connections.find(
            (connection) =>
                Object.values(connection.auth.teamIdToTeamRole)[0] ===
                Object.values(response.result.success.teamIdToTeamRole)[0]
        )
        if (existingConnection) existingConnection.auth = response.result.success
        else
            connections.push({
                type: 'parsable',
                connectionUrl: '',
                auth: response.result.success,
            })
        localStorage.setItem('external_connections', JSON.stringify(connections))
        window.location.href = '/'
    }
    return (
        <div className="login-form">
            <h1>Parsable Login</h1>
            {response?.result?.err ? <b>{response.result.err.message}</b> : <></>}
            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
                <OutlinedInput
                    id="email"
                    type="text"
                    label="Email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                />
            </FormControl>
            <br />
            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                    value={password}
                    label="Password"
                />
            </FormControl>
            <br />
            <Button
                onClick={async () => {
                    let response = await login(email, password)
                    setResponse(response)
                }}
                disabled={buttonDisabled}
                className={'button big-button' + (buttonDisabled ? ' disabled' : '')}
                variant="contained"
            >
                Log In
            </Button>
        </div>
    )
}
