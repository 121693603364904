import { ExternalConnection, Account } from '../../../../types'
import {
    ConcreteStep,
    Step,
    Element,
    ElementId,
    StepId,
    ChoiceId,
} from '../../../../../../shared/domain/flows/types.no-deps'
import type { SyncFlowWorkflow } from '../../../../../../shared/domain/workflows/types.no-deps'
import type { Job } from './types'
import { v4 as uuidv4 } from 'uuid'
import { useDatePickerDefaultizedProps } from '@mui/x-date-pickers/DatePicker/shared'
import { findElement, uploadImage, postImage } from '../../logic'
import { AddWorkflowOutput, AddEndpoint } from '../../../../../../server/http/workflows/add.no-deps'
import { isConcreteStep } from '../../helper'
export const login = async (email: string, password: string) => {
    try {
        var options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Wi-Thrift-Version': '1.22.13',
            },
            body: JSON.stringify({
                method: 'login',
                arguments: {
                    email: email,
                    password: password,
                },
            }),
        }

        const response = await fetch('https://api.parsable.net/api/auth', options)

        return await response.json()
    } catch (e) {
        console.log('Error:', e)
        return
    }
}
export const getTemplates = async (connection: ExternalConnection) => {
    try {
        var options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Wi-Thrift-Version': '1.22.13',
                Authorization: 'Token ' + connection.auth.authToken,
            },
            body: JSON.stringify({
                method: 'query',
                arguments: {
                    selectOpts: {
                        includeTeam: false,
                        includeRootHeaders: false,
                        includeSteps: false,
                        includeDocuments: false,
                        includeLastAuthor: true,
                        includeStats: false,
                        includeTags: true,
                        includeDrafts: false,
                        includeLastModified: true,
                        includeAttributes: true,
                        includeRefMap: true,
                        includeOriginalAuthor: true,
                        includeDocumentUrls: true,
                    },
                    whereOpts: {
                        teamId: Object.keys(connection.auth.teamIdToTeamRole)[0],
                    },
                    order: [
                        {
                            field: 3,
                            asc: true,
                        },
                    ],
                },
            }),
        }

        const request = await fetch('https://api.parsable.net/api/job_templates', options)
        const response = await request.json()
        return response.result
    } catch (e) {
        console.log('Error:', e)
        return
    }
}
export const getTemplatesWithSteps = async (connection: ExternalConnection, ids: string[]) => {
    try {
        var options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Wi-Thrift-Version': '1.22.13',
                Authorization: 'Token ' + connection.auth.authToken,
            },
            body: JSON.stringify({
                method: 'fetch',
                arguments: {
                    selectOpts: {
                        includeTeam: false,
                        includeRootHeaders: false,
                        includeSteps: true,
                        includeDocuments: false,
                        includeLastAuthor: true,
                        includeStats: false,
                        includeTags: true,
                        includeDrafts: false,
                        includeLastModified: true,
                        includeAttributes: true,
                        includeRefMap: true,
                        includeOriginalAuthor: true,
                        includeDocumentUrls: true,
                    },
                    templateIds: ids,
                },
            }),
        }

        const request = await fetch('https://api.parsable.net/api/job_templates', options)
        const response = await request.json()
        return response.result
    } catch (e) {
        console.log('Error:', e)
        return
    }
}
export const getJobData = async (connection: ExternalConnection, jobId: string) => {
    try {
        var options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Wi-Thrift-Version': '1.22.13',
                Authorization: 'Token ' + connection.auth.authToken,
            },
            body: JSON.stringify({
                method: 'getData',
                arguments: {
                    jobId: jobId,
                    seqId: 0,
                    options: {
                        canHandlePendingDocuments: false,
                    },
                },
            }),
        }

        const request = await fetch('https://api.parsable.net/api/jobs', options)
        const response = await request.json()

        return response.result
    } catch (e) {
        console.log('Error:', e)
        return
    }
}
export const getCompletedJobs = async (connection: ExternalConnection, templateIds: string[], since: number) => {
    try {
        var options = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Wi-Thrift-Version': '1.22.13',
                Authorization: 'Token ' + connection.auth.authToken,
            },
            body: JSON.stringify({
                method: 'query',
                arguments: {
                    selectOpts: {
                        includeTeam: false,
                        includeTemplate: false,
                        includeRootHeaders: false,
                        includeSteps: false,
                        includeDocuments: false,
                        includeUsers: false,
                        includeStats: false,
                        includeActivity: false,
                        includeTemplates: false,
                        includeCreator: false,
                        includeRoles: false,
                        includePermissions: false,
                        includeExecSnippets: false,
                        includeMessages: false,
                        includeIssues: false,
                        includeDeviationCounts: false,
                        includeDeviations: false,
                        includeRefMap: false,
                        includePlannedDataSheetIds: false,
                        includeSnapshottedDataSheetValues: false,
                        includeAttributes: false,
                    },
                    whereOpts: {
                        sourceTemplateIds: templateIds,
                        teamId: Object.keys(connection.auth.teamIdToTeamRole)[0],
                        modifiedSinceTime: since,
                    },
                    order: [
                        {
                            field: 1,
                            asc: true,
                        },
                    ],
                },
            }),
        }

        const request = await fetch('https://api.parsable.net/api/jobs', options)
        const response = await request.json()
        if (!response?.result?.success?.jobs) throw 'Error getting jobs'

        return response.result.success.jobs
    } catch (e) {
        console.log('Error:', e)
        return
    }
}
export function templateToFlow(template: Job, subjectUnit: number | null): SyncFlowWorkflow['flow'] {
    let steps: ConcreteStep[] = []
    let notices: Step = {
        id: uuidv4() as StepId,
        label: '',
        elements:
            template.stepGroup?.impl?.stepGroup?.headers?.notices?.length > 0
                ? template.stepGroup.impl.stepGroup.headers.notices.map((notice) => {
                      return {
                          id: uuidv4() as ElementId,
                          type: 'text-content',
                          label: '',
                          richText: [
                              {
                                  children: [
                                      {
                                          text: notice.descrip,
                                      },
                                  ],
                              },
                          ],
                          displayType: notice.descrip == 'Caution' ? 'warning' : 'info',
                      }
                  })
                : [],
        defaultRoute: 'Step.NextStep',
        conditionalRoutes: [],
        guidesDynamicFilter: null,
    }
    steps.push(notices)
    steps.push(
        ...template.stepGroup.children.map((step) => {
            const z_step: Step = {
                id: step.id as StepId,
                label: step.title,
                guides: [],
                elements: step.impl?.step?.stepFields
                    ? (step.impl.step.stepFields
                          .map((field): Element | null => {
                              if (field.type === 1)
                                  // Text
                                  return {
                                      id: field.id as ElementId,
                                      type: 'text-capture',
                                      label: field.key,
                                      showIfs: [],
                                      multipleLines: false,
                                      required: field.isRequired === true,
                                  }
                              if (field.type === 2)
                                  // Number
                                  return {
                                      id: field.id as ElementId,
                                      type: 'number',
                                      label: field.key,
                                      showIfs: [],
                                      required: field.isRequired === true,
                                  }
                              if (field.type === 3 && field.fieldData?.listOptions?.options)
                                  // Option
                                  return {
                                      id: field.id as ElementId,
                                      type: 'radio',
                                      label: field.key,
                                      showIfs: [],
                                      choices: field.fieldData.listOptions.options.map((option) => {
                                          return {
                                              id: uuidv4() as ChoiceId,
                                              label: option,
                                          }
                                      }),
                                      required: field.isRequired === true,
                                  }

                              if (field.type === 4)
                                  //Photo
                                  return {
                                      id: field.id as ElementId,
                                      type: 'photo',
                                      label: field.key,
                                      required: field.isRequired === true,
                                      minPhotos: 1,
                                      kind: 'photo',
                                  }
                              if (field.type === 5)
                                  // Video
                                  return {
                                      id: field.id as ElementId,
                                      type: 'photo',
                                      label: field.key,
                                      required: field.isRequired === true,
                                      kind: 'video',
                                  }
                              if (field.type === 6)
                                  // Audio
                                  return {
                                      id: field.id as ElementId,
                                      type: 'photo',
                                      label: field.key,
                                      required: field.isRequired === true,
                                      kind: 'video',
                                  }

                              if (field.type === 7)
                                  // Signature
                                  return {
                                      id: field.id as ElementId,
                                      type: 'signature',
                                      label: field.key,
                                      required: field.isRequired === true,
                                  }

                              if (field.type === 8)
                                  // Date
                                  return {
                                      id: field.id as ElementId,
                                      type: 'datetime',
                                      label: field.key,
                                      selectType: 'date',
                                      required: field.isRequired === true,
                                  }

                              if (field.type === 9)
                                  // Yes No
                                  return {
                                      id: field.id as ElementId,
                                      type: 'yes-no',
                                      label: field.key,
                                      required: field.isRequired === true,
                                      useIcons: true,
                                      iconSize: 'medium',
                                  }
                              if (field.type === 10)
                                  // Precise Number
                                  return {
                                      id: field.id as ElementId,
                                      type: 'number',
                                      label: field.key,
                                      showIfs: [],
                                      required: field.isRequired === true,
                                      step: 0.01,
                                  }
                              if (field.type === 11 && field.fieldData?.listOptions?.options)
                                  //  Select list values
                                  return {
                                      id: field.id as ElementId,
                                      type: 'dropdown',
                                      label: field.key,
                                      showIfs: [],
                                      choices: field.fieldData.listOptions.options.map((option) => {
                                          return {
                                              id: uuidv4() as ChoiceId,
                                              label: option,
                                          }
                                      }),
                                      required: field.isRequired === true,
                                  }
                              if (field.type === 12)
                                  // File select
                                  return {
                                      id: field.id as ElementId,
                                      type: 'files-select',
                                      label: field.key,
                                      required: field.isRequired === true,
                                  }
                              if (field.type === 14)
                                  // Date select
                                  return {
                                      id: field.id as ElementId,
                                      type: 'datetime',
                                      label: field.key,
                                      selectType: 'date',
                                      required: field.isRequired === true,
                                  }
                              if (field.type === 15) return null //Field to notify someone, cannot map

                              return {
                                  id: field.id as ElementId,
                                  type: 'text-capture',
                                  label: field.key,
                                  showIfs: [],
                                  multipleLines: false,
                              }
                          })
                          .filter((element) => element !== null) as Element[])
                    : [],
                defaultRoute: 'Step.NextStep',
                conditionalRoutes: [],
                guidesDynamicFilter: null,
            }

            return z_step
        })
    )
    let flow = {
        id: template.id as ElementId,
        type: 'section',
        steps: steps,
        templates: [],
    } as SyncFlowWorkflow['flow']
    if (subjectUnit && flow)
        flow.subjectType = {
            type: 'SubjectTypeUnit',
            unitType: subjectUnit,
        }
    return flow
}
export async function createReport(
    account: Account,
    connection: ExternalConnection,
    templateName: string,
    job: any,
    workflow: AddWorkflowOutput,
    procedureId: number,
    userId: number
) {
    if (!job || !workflow.workflow.flow) return undefined

    const jobData = await getJobData(connection, job.id)
    if (!jobData.success) return
    const answers = jobData.success
    answers.startedAt = job.startedAt

    var statusId =
        answers.jobCompletedAt === 0
            ? workflow.reportStatuses.find((status) => status.name === 'In Progress')?.id
            : workflow.reportStatuses.find((status) => status.name === 'Closed')?.id

    const flat_answers = answers.stepExecutionData?.map((step: any) => step.fieldExecutionData).flat()

    const z_answers = []
    const templateId = answers.jobId
    //const templateId = uuidv4()
    for (var i = 0; i < flat_answers.length; i++) {
        const answer = flat_answers[i]

        if (!workflow.workflow.flow) continue

        const flowQuestion = findElement(workflow.workflow.flow.steps, answer.fieldId)

        if (!flowQuestion) {
            console.log('Cannot find question', answer)
            continue
        }

        if (!answer.execData) {
            console.log('No exec data', answer)
            continue
        }

        let value

        if (answer.execData.text && flowQuestion.type === 'text-capture') {
            value = answer.execData.text
        }

        if (answer.execData.signature && flowQuestion.type === 'signature') {
            value = await downloadDocument(connection, account, answer.execData.signature)
        }
        if (answer.execData.documents && answer.execData.documents.documentIds && flowQuestion.type === 'photo') {
            value = []
            for (var j = 0; j < answer.execData.documents.documentIds.length; j++) {
                const photo = await downloadDocument(connection, account, answer.execData.documents.documentIds[j])
                if (photo)
                    value.push({
                        type: 'PhotoFile',
                        photo: photo,
                    })
            }
            if (value.length === 0) value = undefined
        }

        if (
            answer.execData.option &&
            (flowQuestion.type === 'radio' || flowQuestion.type === 'dropdown' || flowQuestion.type === 'checkbox')
        ) {
            value = flowQuestion.choices.find((choice) => choice.label == answer.execData.option)?.id
        }

        if (
            answer.execData.selectedListValues?.values &&
            (flowQuestion.type === 'radio' || flowQuestion.type === 'dropdown' || flowQuestion.type === 'checkbox')
        ) {
            value = answer.execData.selectedListValues.values
                .map((value: string) => flowQuestion.choices.find((choice) => choice.label == value)?.id)
                .filter((option: ChoiceId | undefined) => !!option)
        }

        if (answer.execData.date && flowQuestion.type === 'datetime') {
            let date = new Date(answer.execData.date * 1000)
            value = {
                type: 'date',
                timezoneOffsetInMs: 0,
                date: {
                    day: date.getDay(),
                    month: date.getMonth(),
                    year: date.getFullYear(),
                },
            }
        }

        if (answer.execData.yesNo !== undefined && flowQuestion.type === 'yes-no') {
            value = answer.execData.yesNo ? 'yes' : 'no'
        }

        if (answer.execData.number !== undefined && flowQuestion.type === 'number') {
            value = answer.execData.number
        }

        if (answer.execData.preciseNumber !== undefined && flowQuestion.type === 'number') {
            value = answer.execData.preciseNumber.number
        }

        if (!value) {
            console.log('Cannot map answer', answer, flowQuestion)
            continue // Skip
        }

        z_answers.push({
            type: flowQuestion.type === 'photo' ? 'extensible-files' : flowQuestion.type,
            id: uuidv4(),
            element: answer.fieldId,
            latitude: null,
            longitude: null,
            report: templateId,
            timestamp: new Date(answer.lastUpdatedAt * 1000).toISOString(),
            user: userId,
            value: value,
            loopPath: '',
        })
    }
    z_answers.push({
        type: 'section',
        id: uuidv4(),
        element: workflow.workflow.flow.id,
        latitude: null,
        longitude: null,
        report: templateId,
        timestamp:
            answers.jobCompletedAt === 0
                ? new Date().toISOString()
                : new Date(answers.jobCompletedAt * 1000).toISOString(),
        user: userId,
        value: 'completed',
        oldAnswer: null,
        loopPath: '',
    })

    var report = {
        clientId: templateId,
        procedure: procedureId,
        workflow: {
            id: workflow.workflow.id,
            version: workflow.workflow.numberOfVersions,
        },
        coordinates: { lat: null, lon: null },
        startDate: new Date(answers.startedAt * 1000).toISOString(),
        teamDoneFor: null,
        isAction: false,
        flowUnitIds: {},
        loopPlan: [],
        cachedUnitData: [],
        reportStatusId: 1,
        location: 1,
        scheduledInstance: null,
        calendarEvent: {
            data: {},
            startDate: new Date(answers.startedAt * 1000).toISOString(),
            endDate: new Date(answers.startedAt * 1000).toISOString(),
            recurring: false,
            location: 1,
            template: workflow.workflow.id,
            campaign: procedureId,
        },
        subjectUnitId: null,
        post: {
            id: uuidv4(),
            unit: null,
            location: 1,
            workflow: workflow.workflow.id,
            procedure: procedureId,
            createdAt: new Date(answers.startedAt * 1000).toISOString(),
            createdBy: userId,
            lastModifiedAt: new Date(answers.startedAt * 1000).toISOString(),
            lastModifiedBy: userId,
            lastModifiedType: 'created',
            serverSyncTime: new Date().toISOString(),
            isDeleted: false,
            commentCount: 0,
            contentType: 'report',
            reportActivities: 0,
            reportWorkflowName: templateName,
            reportClientId: templateId,
        },
        status: statusId,
        answers: z_answers,
    }

    report.answers = report.answers.filter((answer: any) => answer !== undefined)

    return report
}
function getImageExtension(contentType: string): string | null {
    const contentTypeMap: { [key: string]: string } = {
        'image/jpeg': '.jpg',
        'image/png': '.png',
        'image/gif': '.gif',
        'image/webp': '.webp',
        'image/bmp': '.bmp',
        'image/tiff': '.tiff',
        'image/x-icon': '.ico',
        'image/svg+xml': '.svg',
        'image/heic': '.heic',
        'image/heif': '.heif',
    }

    return contentTypeMap[contentType.toLowerCase()] || null
}

export async function downloadDocument(connection: ExternalConnection, destination: Account, documentId: string) {
    const url = 'https://api.parsable.net/api/documents/' + documentId
    const resp = await fetch(url, {
        method: 'get',
        headers: {
            Authorization: 'Token ' + connection.auth.authToken,
        },
    })
    if (resp.ok && resp.body) {
        var file = await resp.blob()
        const response = await uploadImage(destination, file, {
            fileName: documentId,
            fileSizeInBytes: file.size,
            extension: getImageExtension(resp.headers.get('Content-Type') || '.jpg'),
            fileId: documentId,
        })
        const regex = /files\/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/

        // Extract the UDID using match
        const match = response.match(regex)

        if (match) {
            const udid = match[1] // The UDID is captured in the first capture group
            return udid
        } else {
            console.log('No UDID found in the URL.')
            return undefined
        }
    }
}

export function makeAllOption(
    flow: AddEndpoint['Body'] & {
        procedure: {
            id: number
            name: string
        }
    }
): AddEndpoint['Body'] & {
    procedure: {
        id: number
        name: string
    }
} {
    if (!flow.flow) return flow
    flow.flow.steps = flow.flow.steps.map((step) => {
        if (isConcreteStep(step))
            step.elements = step.elements.map((element) => {
                if ('required' in element && element.required !== undefined) element.required = false
                return element
            })
        return step
    })
    return flow
}
